exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-commerce-law-tsx": () => import("./../../../src/pages/commerce_law.tsx" /* webpackChunkName: "component---src-pages-commerce-law-tsx" */),
  "component---src-pages-extract-checkout-tsx": () => import("./../../../src/pages/extract/checkout.tsx" /* webpackChunkName: "component---src-pages-extract-checkout-tsx" */),
  "component---src-pages-extract-index-tsx": () => import("./../../../src/pages/extract/index.tsx" /* webpackChunkName: "component---src-pages-extract-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-storage-checkout-tsx": () => import("./../../../src/pages/storage/checkout.tsx" /* webpackChunkName: "component---src-pages-storage-checkout-tsx" */),
  "component---src-pages-storage-index-tsx": () => import("./../../../src/pages/storage/index.tsx" /* webpackChunkName: "component---src-pages-storage-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

